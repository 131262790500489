import { transformImg } from '@shein/common-function'
import { STANDARD_FONT_SIZE } from './constants'
import { getMainImg, getSecondImg, addProductDetailBfCache } from 'public/src/pages/components/product/item_v3/js/utils'
import { views } from 'public/src/pages/components/product/item_v3/js/constant'
import { getProductDetailUrl } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { parseQueryString } from '@shein/common-function'
import expose from 'public/src/services/expose/index.js'
import { transfromAndCutImg } from 'public/src/services/resource/index'

export const isClient = typeof window !== 'undefined'

export const getScrollTop = () => {
  return window.scrollY || window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
}

export const getRatio = () => {
  if (typeof window === 'undefined') return 1
  const rootFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize)
  return rootFontSize / STANDARD_FONT_SIZE
}

export const getStringWidth = (str, font) => {
  if (!document) return
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.font = font
  return context.measureText(str).width
}

const tweenMap = {
  ease: (t, b, c, d) => {
    // 以 easeOutQuad 为例
    t /= d
    return -c * t * (t - 2) + b
  },
  bounceEaseOut: function(t, b, c, d) {
    if ((t /= d) < (1 / 2.75)) {
      return c * (7.5625 * t * t) + b
    } else if (t < (2 / 2.75)) {
      return c * (7.5625 * (t -= (1.5 / 2.75)) * t + .75) + b
    } else if (t < (2.5 / 2.75)) {
      return c * (7.5625 * (t -= (2.25 / 2.75)) * t + .9375) + b
    } else {
      return c * (7.5625 * (t -= (2.625 / 2.75)) * t + .984375) + b
    }
  }
}

export function smoothScrollTo(targetPosition, duration, tween = 'ease', callback) {
  const startPosition = getScrollTop()
  const distance = targetPosition - startPosition
  let startTime = null

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime
    const timeElapsed = currentTime - startTime
    const scrollY = Math.ceil(tweenMap[tween](timeElapsed, startPosition, distance, duration))
    window.scrollTo(0, scrollY)
    if (timeElapsed < duration && scrollY < targetPosition) requestAnimationFrame(animation)
    else {
      callback && callback(startPosition, targetPosition)
    }
  }

  requestAnimationFrame(animation)
}

const BIG_TO_SAMLL_DIFF = 111 - 48

export const getTrendLandingTop = (withTopNav = false) => {
  try {
    const mainElement = document.querySelector('.trend-landing-main__container')
    const navElement = document.querySelector('.trend-header__mid')
    const targetPosition = mainElement.offsetTop - navElement.clientHeight + 1 + (withTopNav ? BIG_TO_SAMLL_DIFF : 0)
    return targetPosition
  } catch (e) {
    console.log('getTrendLandingTop---', e)
    return 260
  }
}

export const gotoTrendLandingTop = ({ force = false, useNative = false, cb, withTopNav = false } = {}) => {
  const targetPosition = getTrendLandingTop(withTopNav)
  const startPosition = getScrollTop()
  if (!force && startPosition > targetPosition) return
  if (!force && window.isBodyFixed) return
  if (window.isBodyFixed) {
    const $body = document.getElementsByTagName('body')[0]
    $body.style.top = `-${targetPosition}px`
    $body.setAttribute('data-offset-top', targetPosition)
  } else {
    if (useNative) {
      window.scrollTo({
        top: targetPosition,
      })
    } else
      smoothScrollTo(targetPosition, 150, 'ease', cb)
  }
}

export const getTextWidth = (text, font) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return Math.ceil(metrics.width)
}

export const setElAttr = ({
  productItem = {},
  config = {},
  el = null,
  index = 0,
}) => {
  const spuImg = ((productItem?.spu_image || [])[0]?.medium_image) || ''  
  const pretreatInfo = productItem?.pretreatInfo || {}

  const { sheinClubPromotionInfo, estimatedPriceInfo, exclusivePromotionInfo } = productItem || {}

  const mixPromotionInfo = pretreatInfo?.mixPromotionInfo || []
  const promoLabelSrc = config.showPromoLabel && productItem?.promoLabel?.src
  const isSoldOut = productItem.is_on_sale == 0 || productItem.stock == 0 || productItem.sale_percent == 1
  // 到手价的一些信息
  const { estimatedPrice, satisfied, estimatedCouponTypeId } = productItem.estimatedPriceInfo || {}
  const estimatedPriceUsdAmount = estimatedPrice?.usdAmount // 到手美元价
  const satisfiedVal = [2, 1][satisfied] || ''
  const isNewUserEstimatedPrice = [23, 56, 57, 65].includes(estimatedCouponTypeId) // 新客到手价[pageId=1340027622]
  const isNewCouponProduct = productItem?.isNewCoupon
  const estimatedPriceWithSymbolVal = config.showEstimatedPriceOnSale && (satisfied === 1) && estimatedPrice?.amountWithSymbol
  const isShowNewuseronlyPriceLabel = config.showNewuseronlyPriceLabel && isNewUserEstimatedPrice && isNewCouponProduct && estimatedPriceWithSymbolVal // 当前为新客到手价 & 根据ab展示新客到手价说明

  const labelsFromKey = views.LIST_MOBILE_VERTICAL_VIEW
  // 31品类限定商家限时直降 wiki.pageId=1198803653
  let showSellerLimitedLabel = ''
  if (mixPromotionInfo.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)) {
    showSellerLimitedLabel = 'show_seller_limited_label'
  }

  // 图片
  const mainImgForDetail = getMainImg(productItem)
  const secondImageForDetail = getSecondImg(productItem)
  
  if (!config.useOwnClickExposeAnalysis) {
    el.setAttribute('da-event-click', (config.itemDAEventClickId || '2-3-1'))
    el.setAttribute('da-event-expose', config.itemDAEventExposeId || '2-3-2')
  }
  // warning: 子组件还有部分埋点上报，搜索setAttrForAnalysis查看
  const extMarks = productItem?.ext?.marks ? `marks_${productItem.ext.marks}` : ''

  el.setAttribute('data-sku', productItem?.goods_sn || '')
  el.setAttribute('data-spu', productItem?.productRelationID || '')
  el.setAttribute('data-id', productItem?.goods_id || '')
  el.setAttribute('data-name', productItem?.goods_name || '')
  el.setAttribute('data-rec_mark', productItem?.ext?.rec_mark || '')
  el.setAttribute('data-ext_marks', extMarks)
  el.setAttribute('data-extra_mark', productItem?.ext?.extra_mark || '')
  el.setAttribute('data-other_ext_mark', productItem?.ext?.other_ext_mark || '')
  el.setAttribute('data-other_d_ext_mark', productItem?.dynamic_ext?.other_d_ext_mark || '')
  // 类目反馈组件测试用的
  el.setAttribute('data-cat_name_temp', productItem?.cat_name || '')
  el.setAttribute('data-cat_id_temp', productItem?.cat_id || '')
  // el.setAttribute('data-cat_id', productItem?.cat_id || '')
  el.setAttribute('data-price', productItem?.salePrice?.amount || '')
  el.setAttribute('data-us-price', productItem?.salePrice?.usdAmount || '')
  el.setAttribute('data-us-origin-price', productItem?.retailPrice?.usdAmount || '')
  el.setAttribute('data-index', index)
  el.setAttribute('data-type', productItem?.dataSource || '') // config.moduleFrom == 'recommend'
  el.setAttribute('data-similar', isSoldOut && config.showSimilarBtn || '')
  el.setAttribute('data-reduce-price', !isSoldOut && pretreatInfo.reducePrice?.usdAmount || '')
  el.setAttribute('data-soldout', Number(isSoldOut)) // data-soldout 目前只有在最近页面有用到
  el.setAttribute('data-sold-out', Number(isSoldOut)) // data-sold-out 需要用到其他页面上，因为上报的标识不一样，需要另外设置一个属性
  el.setAttribute('data-show-exclusive-price', productItem.exclusivePromotionPrice || '')
  el.setAttribute('data-lable', pretreatInfo.label || '')
  el.setAttribute('data-series-brand', !promoLabelSrc && pretreatInfo.seriesOrBrandAnalysis || '')
  el.setAttribute('data-brand', productItem?.brand?.name || '')
  el.setAttribute('data-brand-code', productItem?.brand?.brand_code || '')
  el.setAttribute('data-video', productItem?.video_url ? 'video_icon' : '')
  el.setAttribute('data-spu-img', spuImg ? 'spupic_0' : '')
  el.setAttribute('data-mall_tag_code', `${pretreatInfo.mallTagsInfo?.mall_tags || ''}_${productItem?.mall_code || '-'}`)
  el.setAttribute('data-store_code', productItem?.store_code ?? '')
  el.setAttribute('data-sale-attr', pretreatInfo.saleAttr?.analysis || '')
  el.setAttribute('data-price-cut', pretreatInfo.priceCut || '')
  el.setAttribute('data-promotion-id', mixPromotionInfo.map(item => item?.id).join('`') || '')
  el.setAttribute('data-type-id', mixPromotionInfo.map(item => item?.typeId).join('`') || '')
  el.setAttribute('data-best-deal', pretreatInfo.showBestDeal ? 1 : '')
  el.setAttribute('data-promo-label', promoLabelSrc ? 1 : '')
  el.setAttribute('data-belt-label', pretreatInfo[labelsFromKey]?.beltLabel?.ana || '')
  el.setAttribute('data-user-preferences-label', pretreatInfo[labelsFromKey]?.sellingPointUniversalLabels?.[0]?.userPreferencesLabel?.ana || '')
  el.setAttribute('data-show-seller-limited-label', showSellerLimitedLabel)
  el.setAttribute('data-show-newuseronly-price-label', isShowNewuseronlyPriceLabel ? 'show_new_user_Price' : '')

  // 只有ab价没有其他价格才需要上报
  const onlyAbPrice = productItem?.promotionInfo?.some(item => +item?.typeId === 32) && !sheinClubPromotionInfo?.price && !exclusivePromotionInfo?.price && !estimatedPriceInfo?.estimatedDiscount
  if (onlyAbPrice) {
    el.setAttribute('data-is-ab-price', 'estimated_price_3_noestimate')
  }

  const badges = []
  const dataBadges = el.getAttribute('data-badges')
  if (dataBadges) badges.push(dataBadges)
  el.setAttribute('data-badges', badges)

  if (satisfiedVal && estimatedPriceUsdAmount) {
    el.setAttribute('data-estimated-price', `${satisfiedVal}\`${estimatedPriceUsdAmount}`)
  }
  el.setAttribute('data-main-img', mainImgForDetail || '')
  el.setAttribute('data-second-img', secondImageForDetail || '')
  // 趋势词相关
  const { trend_word_id, product_select_id } = productItem?.deliveryInfo || {}
  const { isModStore, storeCode, title } = productItem?.storeInfo  || {}
  if (trend_word_id) {
    el.setAttribute('data-trend-word-id', trend_word_id || '')
    el.setAttribute('data-trend-product-select-id', product_select_id || '')
  } else if (isModStore) {
    el.setAttribute('data-trend-shop-code', storeCode ? storeCode + '_' + title : '')
  }
}

export const handleGoodsUrl = async ({ goods_url_name = 'product', goods_id, cat_id, mall_code, goods_img, config }) => {

  const transformSrc = transformImg({ img: goods_img })

  const productDetailUrl = await getProductDetailUrl({
    imgSrc: transformSrc,
    goods_url_name,
    goods_id,
    cat_id,
    mall_code,
    urlQuery: config.urlQuery,
    langPath: window.gbCommonInfo.langPath,
  })

  return productDetailUrl
}

export const handleJumpToDetailPage = async ({
  item,
  router = {},
  config = {},
}) => {
  const { goods_url_name, goods_id, cat_id, mall_code, goods_img } = item
  const url = await handleGoodsUrl({
    goods_url_name,
    goods_id,
    cat_id,
    mall_code,
    goods_img,
    config,
  })
  addProductDetailBfCache(item)
  if (router && url) {
    router.push(url)
  }
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const updateQueryWithoutReload = (newQuery) => {
  const currentPath = window.location.pathname
  const currentHash = window.location.hash
  const newQueryObj = {
    ...parseQueryString(location.search),
    ...newQuery
  }
  const newUrl = `${currentPath}${currentHash}?${new URLSearchParams(newQueryObj)}`
  history.replaceState(history.state ?? null, null, newUrl)
}

// swiper在视口内才播放
export function handleAutoPlay(swiperEl, exposeOptions = {
  mask: ['0', '0', '0', '0'],
}) {
  if(!swiperEl) return
  const obInstance = new expose({ observeHide: true, exposeRatio: 0.99, mask: exposeOptions.mask })
  obInstance.observe(
    {
      elements: swiperEl,
      once: false,
    },
    ({ exposeDoms }) => {
      if (!swiperEl.swiper) return
      if (exposeDoms.length) {
        swiperEl.swiper?.autoplay?.resume()
      } else {
        swiperEl.swiper?.autoplay?.pause()
      }
    },
  )
  return obInstance
}


export const cutImg = ({ imgUrl, designWidth, exp, locals } = {}) => {
  try {
    const { RESOURCE_SDK = {} } = locals || {}
    const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

    const cutData = {
      deviceData,
      isSupportWebp: Boolean(isSupportWeb),
      isSupprotCut,
      imgUrl,
      designWidth: Number(designWidth),
      sceneMap,
      exp,
    }

    return transfromAndCutImg(cutData)
  } catch (e) {
    return imgUrl
  }
}

