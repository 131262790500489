export const DEFAULT_CLIENT_WIDTH = 375
export const BANNER_HEIGHT = 331
export const NAV_HEIGHT = 44
export const NEW_NAV_HEIGHT = 62
export const NEW_NAV_SHADOW_HEIGHT = 118
export const STANDARD_FONT_SIZE = 37.5 

export const PAGE_SIZE = 20

export const LAZY_IMG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXcAAAF3BAMAAACs7hbNAAAAIVBMVEXl5eXNzc3R0dHi4uLe3t7b29vZ2dnV1dXT09Ph4eHg4OAvaRVoAAADo0lEQVR42uzBAQEAAACCoP6vbojAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACg4eyZzU8TQRiH32S3NcvtHaDtdabFAqcNII2cNlAg5VSsFzyBrY3cWIlRbnaJETmRWA16MoIR/SsdZz9my7bdVs2rhz7ZtJOd3eyzb347sx9mW3LoQESt2t0Iur7vdMOVO91PtesfteqK7E5saDQgZPPDtQNUrLkoYYUNCMgIIUqgeCybtmpNCQnj8scTgtdB8VKIctDNLsDH4pyXgAiLow8L7bOIOAOKXUTm22UwzhEo3Kh7ChfBx+ggzgIRlxiStxPyB6PKZ5F9DuQ5gbwuVMTxmPImj3cv0MtfYkTOHjk2++GJ69gguwpadPIuSubPOsooIe/2yLOzgDeQzDxijjDzWn7OhswDpTE8NguguRWbOyibx8SVN1HiyIa1vgdpo00pcb3o7uiKN1wK+eighbDkabHRlU/GJrriLU4prw+VFpvFwbHJRoOtwf+Xyg+PjRWPjeLEJs/83l+KjaQuM0872hQuUio/IDZT8dj4vAaTepwvN2/Lzz7Zetja3OS9sfm63FhdbsoliE28O2Df6FDPsOzc7pVnGMIutB0XTC3FgbHBnEVYeQt9njtx+Tj7obxmenDlsUUoD9vok3f6y2s7TTEhr/cpUMobHvqU7JHlpwfGRkIpDysd9Hk7VuWT4/x8h14eTBcVBVvL59uK3lAX2gGNvjPs0RKhvLZfR4WTGOd75WeGT1KnpqvlCbnXUWlImaQWht9VliCr5SlZ/WU/l3Jjpp36x0ZuTy2vJ6t8ivzp8NhI+ey/kbe0/PgPI/rcDojl9UN//s8eA0sqfqTy3070+JhyS1xMi41kl1Le5OzQBjBuRojNzODYmKG85RLKryNiqwtbKCmmVL60dvWx+67Wve8kYlOJZugKnfwUR4nXRiWfknn0ysI7m/dE2ekXmxL1e5sljFEf90VrMjaSJTL5CseIHPz+u8qKljeekWV+G0NYa1x5cHsq/yqKIpW8TEaA9B0vNsnMB3whkzduOErEXTv+ZeQcFE8ZF0FpBWOCcy6Y/Bd1f53Hwi8jlidEEwLMOSAj80LiQIyqbl2vhifZaFTfZ1Z2Gs1HANE66EsVJkyYMOEne3AgAAAAAADk/9oIqqqqqqqqqsIeHAgAAAAAAPm/NoKqqqqqqqqqqqqqqqqqqtIeHAgAAAAACPK33mCCCgAAAAAAAAAAAF7Ss94IX2NCdgAAAABJRU5ErkJggg=='

export const ANIMATION_TAG = 'trend_landing_animation'

export const TREND_LANDING = 'trend_landing'
export const TOP_TREND = 'top_trend'
export const TREND_OUTFITS = 'trend_outfits'
export const TREND_WALL = 'toptrend_wall'
